import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

export default function MenuContent({
  mainListItems = [],
  secondaryListItems = [],
  handleItemSelect,
  selectedIconId,
}) {
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      {mainListItems.length > 0 && (
        <List dense>
          {mainListItems.map((item) => (
            <ListItem
              key={item.icon_id}
              disablePadding
              sx={{ display: "block" }}
            >
              <Link
                to={item.path}
                onClick={() => handleItemSelect(item.icon_id)}
              >
                <ListItemButton selected={selectedIconId === item.icon_id}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      )}

      {secondaryListItems.length > 0 && (
        <List dense>
          {secondaryListItems.map((item) => (
            <ListItem
              key={item.icon_id}
              disablePadding
              sx={{ display: "block" }}
            >
              <Link
                to={item.path}
                onClick={() => handleItemSelect(item.icon_id)}
              >
                <ListItemButton selected={selectedIconId === item.icon_id}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
}
