import React, { useContext, useState } from "react";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { ColorLoadingButton } from "../../../../users/screens/addEmployeeModal";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Grid2";

import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import CustomTextInput from "../../../../../components-general/textInput/customTextInput";
import CustomSelect from "../../../../../components-general/selector/customSelect";
import CustomTextArea from "../../../../../components-general/textArea/customTextArea";
import { ApiPostContext } from "../../../../../context/apiCalls/apiPostContext";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  //border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const statuses = [
  { title: "Completed", value: "completed" },
  { title: "In Progress", value: "in progress" },
  { title: "Update", value: "update" },
  { title: "Starting", value: "to start" },
  { title: "Testing", value: "test" },
];

export const categories = [
  { title: "Preconception", value: "Preconception" },
  { title: "Pregnancy", value: "Pregnancy" },
  { title: "Postpartum", value: "Postpartum" },
  { title: "General", value: "General" },
  { title: "Clinician", value: "Clinician" },
  { title: "Testing", value: "test" },
];

export const taskTypes = [
  { title: "Data Management", value: "dataManagement" },
  { title: "Assessment", value: "assessment" },
  { title: "Getting Started", value: "getStarted" },
  { title: "Testing", value: "test" },
];
export default function AddTaskForUsers({ open, setOpen, setTriggerFetch }) {
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");

  const [typeOfTask, setTypeOfTask] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [nav, setNavigation] = useState("");

  const { postTask } = useContext(ApiPostContext);

  const notify = () => {
    toast.success("Successfully Saved !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const notifyError = () => {
    toast.error("Error Saving !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };
  const handleClose = () => setOpen(false);

  const handleClick = async (event) => {
    event.preventDefault();
    const details = {
      name: name,
      status: status,
      typeOfTask: typeOfTask,
      subtitle: subtitle,
      category: category,
      navName: nav,
    };

    setIsLoading(true);

    try {
      const result = await postTask(details);
      if (result.success) {
        console.log("Successful");
        notify();
        setTriggerFetch((prev) => prev + 1);
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="font-bold text-lg text-mainColor mb-5 ">Add Task</p>

          <form>
            <div>
              <ToastContainer />

              <Grid2
                container
                spacing={2}
                columns={6}
                sx={{ mb: (theme) => theme.spacing(2) }}
              >
                <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
                  <CustomSelect
                    items={categories}
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                    label="Task Category"
                    defaultText="Select Category "
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
                  <CustomSelect
                    items={taskTypes}
                    onChange={(e) => setTypeOfTask(e.target.value)}
                    value={typeOfTask}
                    label="Type of Task "
                    defaultText="Select Task Type "
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
                  <CustomSelect
                    items={statuses}
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                    label="Default Status "
                    defaultText="Select Status "
                  />
                </Grid2>
              </Grid2>
              <Grid2
                container
                spacing={2}
                columns={6}
                sx={{ mb: (theme) => theme.spacing(2) }}
              >
                <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
                  <CustomTextInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter name here ..."
                    label="Name "
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
                  <CustomTextInput
                    value={nav}
                    onChange={(e) => setNavigation(e.target.value)}
                    placeholder="Enter navigation name here ..."
                    label="Navigation Name "
                  />
                </Grid2>
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
                <CustomTextArea
                  value={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                  placeholder="Describe the task here"
                  label="Task Description"
                />
              </Grid2>
            </div>

            <Stack className="mt-5 mx-10 " direction="row" spacing={2}>
              <ColorLoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                onClick={handleClick}
              >
                Save
              </ColorLoadingButton>
              <Button onClick={handleClose} variant="outlined" size="medium">
                Cancel
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
