import { useSelector } from "react-redux";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SelectContent from "./SelectContent";
import MenuContent from "./MenuContent";
import OptionsMenu from "./OptionsMenu";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import AddTaskIcon from "@mui/icons-material/AddTask";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { GrUserWorker } from "react-icons/gr";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: "border-box",
  },
});

export const adminSection = [
  {
    icon_id: "A2",
    icon: <EngineeringIcon />,
    text: "Admins",
    path: "admins",
  },

  {
    icon_id: "A3",
    icon: <GrUserWorker />,
    text: "Employee",
    path: "employees",
  },
];

export const momSection = [
  { icon_id: "M2", text: "Users", path: "users", icon: <PeopleRoundedIcon /> },
  { icon_id: "M3", text: "Badges", path: "badge", icon: <MilitaryTechIcon /> },
  { icon_id: "M4", text: "Tasks", path: "tasks", icon: <AddTaskIcon /> },
  {
    icon_id: "M5",
    text: "Articles",
    path: "articles",
    icon: <AutoStoriesIcon />,
  },
  {
    icon_id: "M6",
    text: "Daily Tips",
    path: "tips",
    icon: <AutoStoriesIcon />,
  },
];

export const shopSection = [
  { icon_id: "M1", text: "Home", path: "/shop", icon: <HomeRoundedIcon /> },
];
export const clinicianSection = [
  {
    icon_id: "C2",
    icon: <PeopleRoundedIcon />,
    text: "Clinicians",
    path: "clinicians",
  },
];

export const secondaryListItems = [
  {
    icon_id: "D1",
    text: "Settings",
    path: "/shop",
    icon: <SettingsRoundedIcon />,
  },
];

export default function SideMenu() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [selectedCompany, setSelectedCompany] = React.useState(1);

  const navigate = useNavigate();
  React.useEffect(() => {
    // Update the state when the selected company changes
    localStorage.setItem("selectedCompany", selectedCompany);
  }, [selectedCompany]);

  React.useEffect(() => {
    setSelectedCompany(localStorage.getItem("selectedCompany") || 1);
  }, []);

  const [selectedIconId, setSelectedIconId] = React.useState(
    localStorage.getItem("selectedSidebarIconId") ||
      clinicianSection[0].icon_id.toString()
  );
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    if (value === "1") {
      setSelectedIconId(momSection[0].icon_id.toString());
      navigate("/users");
    } else if (value === "2") {
      setSelectedIconId(clinicianSection[0].icon_id.toString());
      navigate("/clinicians");
    } else if (value === "3") {
      setSelectedIconId(adminSection[0].icon_id.toString());
      navigate("/admins");
    } else if (value === "4") {
      setSelectedIconId(shopSection[0].icon_id.toString());
      navigate("/shop");
    }
  };
  const handleItemSelect = (itemId) => {
    setSelectedIconId(itemId.toString()); // Make sure to convert to string
  };
  React.useEffect(() => {
    // Update the state when the selected icon ID changes
    localStorage.setItem("selectedSidebarIconId", selectedIconId);
  }, [selectedIconId]);

  const { profile } = useSelector((state) => state.profile);
  console.log(profile);
  console.log(profile);
  React.useEffect(() => {
    if (profile) {
      setName(profile.name);
      setEmail(profile.email);
    }
  }, [profile]);

  const renderMenuContent = () => {
    if (selectedCompany === "1") {
      // DeaRx Mom
      return (
        <MenuContent
          mainListItems={momSection}
          handleItemSelect={handleItemSelect}
          selectedIconId={selectedIconId}
          secondaryListItems={secondaryListItems}
        />
      );
    } else if (selectedCompany === "2") {
      // DeaRx Clinician
      return (
        <MenuContent
          mainListItems={clinicianSection}
          handleItemSelect={handleItemSelect}
          selectedIconId={selectedIconId}
        />
      );
    } else if (selectedCompany === "3") {
      // DeaRx Admin
      return (
        <MenuContent
          mainListItems={adminSection}
          handleItemSelect={handleItemSelect}
          selectedIconId={selectedIconId}
        />
      );
    } else if (selectedCompany === "4") {
      // DeaRx Admin
      return (
        <MenuContent
          mainListItems={shopSection}
          handleItemSelect={handleItemSelect}
          selectedIconId={selectedIconId}
        />
      );
    }
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", md: "block" },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "background.paper",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          mt: "calc(var(--template-frame-height, 0px) + 4px)",
          p: 1.5,
        }}
      >
        <SelectContent
          setCompany={setSelectedCompany}
          company={selectedCompany}
          onCompanyChange={handleCompanyChange}
        />
      </Box>
      <Divider />
      {renderMenuContent()}
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: "center",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Avatar
          sizes="small"
          alt="Riley Carter"
          src="/static/images/avatar/7.jpg"
          sx={{ width: 36, height: 36 }}
        />
        <Box sx={{ mr: "auto" }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, lineHeight: "16px" }}
          >
            {name}
          </Typography>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {email}
          </Typography>
        </Box>
        <OptionsMenu />
      </Stack>
    </Drawer>
  );
}
