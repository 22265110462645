import { dataDisplayCustomizations } from "../customization/dataDisplay";
import { feedbackCustomizations } from "../customization/feedback";
import { inputsCustomizations } from "../customization/inputs";
import { navigationCustomizations } from "../customization/navigation";
import { surfacesCustomizations } from "../customization/surfaces";
import { getDesignTokens, typography } from "../customization/themePrimitives";

export default function getSignInSideTheme(mode) {
  return {
    ...getDesignTokens(mode),
    typography,

    components: {
      ...inputsCustomizations,
      ...dataDisplayCustomizations,
      ...feedbackCustomizations,
      ...navigationCustomizations,
      ...surfacesCustomizations,
    },
  };
}
