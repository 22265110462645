import React, { useContext, useEffect, useState } from "react";
import "../../../css/scrollbar.css";
import { ApiPostContext } from "../../../context/apiCalls/apiPostContext";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import UpdateIcon from "@mui/icons-material/Update";
import VerifiedIcon from "@mui/icons-material/Verified";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { ColorLoadingButton } from "../../users/screens/addEmployeeModal";
import { ApiUpdateContext } from "../../../context/apiCalls/apiUpdateContext";
import CustomSelect from "../../../components-general/selector/customSelect";
import CustomTextArea from "../../../components-general/textArea/customTextArea";
import CustomUploadImage from "../../../components-general/imageUpload/customUploadImage";
import Editor from "../components/editor";
import NavbarBreadcrumbs from "../../newDashboard/components/NavbarBreadcrumbs";
import MultipleSelectChip from "../../../components-general/selector/mulitselector";

export const allTags = ["Nutrition", "Physical Activity", "Diet"];
const categories = ["Preconception", "Pregnancy", "Postpartum"];

export const authors = [
  { title: "Theodora Attah Mabena", value: "Theodora Attah Mabena" },
  { title: "Daniella Akyea Nkrumah", value: "Daniella Akyea Nkrumah" },
  { title: "Mawufemor Dzikunu", value: "Mawufemor Dzikunu" },
  { title: "Maureen Waithira", value: "Maureen Waithira" },
  { title: "Amos Kamau", value: "Amos Kamau" },
  { title: "Abigael Cheptoo", value: "Abigael Cheptoo" },
  {
    title: "Josephine Enyonam Misroame",
    value: "Josephine Enyonam Misroame",
  },
  { title: "Ruth Wanyama", value: "Ruth Wanyama" },
  { title: "Mzimbasi Ngulube", value: "Mzimbasi Ngulube" },
  { title: "Seraphine Arku", value: "Seraphine Arku" },
  { title: "Demo Account", value: "Demo Account" },
];

const AddNewArticles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([]);
  const [author, setAuthor] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [summary, setSummary] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [content, setContent] = useState("");
  const [update, setUpdate] = useState(false);
  const [tags, setTags] = useState([]);
  const [id, setID] = useState(false);
  const [error, setError] = React.useState("");

  console.log(error);
  const location = useLocation();

  const { postArticle, approveArticle } = useContext(ApiPostContext);
  const { updateArticle } = useContext(ApiUpdateContext);
  const notify = () => {
    toast.success("Successfully Saved !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const notifyError = () => {
    toast.error("Error Saving !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
    //dispatch(setArticleTitle(e.target.value));
  };

  const handleImage = (e) => {
    setCoverImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    //dispatch(setArticleImage(e.target.files[0]));
    //dispatch(setPreviewImage(URL.createObjectURL(e.target.files[0])));
  };

  const handleAuthor = (e) => {
    setAuthor(e.target.value);
    // dispatch(setArticleAuthor(e.target.value));
  };

  const handleSummary = (e) => {
    setSummary(e.target.value);
    //dispatch(setArticleSummary(e.target.value));
  };
  const handleContent = (value, viewUpdate) => {
    setContent(value);
    //dispatch(setArticleContent(value));
  };

  useEffect(() => {
    if (location?.state) {
      const data = location.state;
      setTitle(data?.title);
      setCategory(data?.category);
      setContent(data?.content);
      setAuthor(data?.author);
      setTags(data?.tags);
      setPreviewImage(data?.coverImageUrl);
      setSummary(data?.summary);
      setID(data?.id);
      setUpdate(true);
      console.log("Data from here");
      console.log(data);
    }
  }, [location]);

  const handleClick = async (event) => {
    event.preventDefault();
    const details = {
      title: title,
      summary: summary,
      content: content,
      author: author,
      category: category,
    };
    const data = new FormData();

    data.append("details", JSON.stringify(details));

    data.append("coverImage", coverImage);

    setIsLoading(true);

    try {
      const result = await postArticle(data);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let details = {};
    if (update) {
      details = {
        title: title.toUpperCase(),
        summary: summary,
        content: content,
        author: author,
        category: category,
        id: id,
      };
    } else {
      details = {
        title: title.toUpperCase(),
        summary: summary,
        content: content,
        author: author,
        category: category,
      };
    }

    const data = new FormData();

    data.append("details", JSON.stringify(details));

    data.append("coverImage", coverImage);

    setIsLoading(true);

    try {
      const result = await updateArticle(data);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    setIsApproving(true);
    try {
      const result = await approveArticle(id);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsApproving(false);
    }
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/articles">
      Articles
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      New Article
    </Typography>,
  ];
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <NavbarBreadcrumbs breadcrumbs={breadcrumbs} />
      <ToastContainer />

      <Grid2 container spacing={4}>
        <Grid2 size={{ xs: 12, sm: 8, lg: 9 }}>
          <Box sx={{ width: "100%", maxWidth: "100%" }}>
            <div className="my-5  ">
              <TextField
                value={title}
                onChange={handleTitle}
                id="standard-basic"
                label="Article title"
                variant="standard"
                fullWidth
              />
            </div>
            <Editor onChange={handleContent} value={content} label="Content" />
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
          <Stack className="my-5  " direction="row" spacing={2}>
            {update ? (
              <ColorLoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<UpdateIcon />}
                variant="outlined"
                onClick={handleUpdate}
              >
                Update
              </ColorLoadingButton>
            ) : (
              <ColorLoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                onClick={handleClick}
              >
                Save
              </ColorLoadingButton>
            )}
            <LoadingButton
              loading={isApproving}
              loadingPosition="start"
              startIcon={<VerifiedIcon />}
              variant="outlined"
              onClick={handleApprove}
            >
              Approve
            </LoadingButton>
          </Stack>
          <div className="mb-5  ">
            <MultipleSelectChip
              personName={category}
              setPersonName={setCategory}
              label="Select Categories"
              names={categories}
            />
          </div>
          <div className="mb-5  ">
            <CustomSelect
              items={authors}
              onChange={handleAuthor}
              value={author}
              // label="Author"
              defaultText="Select Author "
            />
          </div>
          <div className="mb-5  ">
            <MultipleSelectChip
              personName={tags}
              setPersonName={setTags}
              label="Select Tags"
              names={allTags}
            />
          </div>
          <div className="mb-5  ">
            <CustomTextArea
              value={summary}
              onChange={handleSummary}
              placeholder="Provide a summary of the article"
              //  label=" Description"
            />
          </div>
          {previewImage && (
            <div className="mb-5">
              <img src={previewImage} width="100%" height={200} alt="article" />
            </div>
          )}
          <div className="mb-5  ">
            <CustomUploadImage onChange={handleImage} />
          </div>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default AddNewArticles;
