import React from "react";
import "react-quill/dist/quill.snow.css";
import "../../../css/scrollbar.css";
import MarkdownEditor from "@uiw/react-markdown-editor";

const Editor = ({ onChange, value }) => {
  console.log(value);
  return (
    <MarkdownEditor
      value={value}
      onChange={onChange}
      className=" max-w-screen-md overflow-auto   bg-white "
      style={{ backgroundColor: "white" }}
    />
  );
};

export default Editor;
