import { configureStore } from "@reduxjs/toolkit";
import tokenSlice from "./token/tokenSlice";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import authSlice from "./auth/authSlice";
import articleSlice from "./articles/articlesSlice";
import recipeSlice from "./recipe/recipeSlice";
import grocerySlice from "./groceries/grocerySlice";
import mealSlice from "./meal/mealSlice";
import profileSlice from "./profile/profileSlice";

const rootReducer = combineReducers({
  token: tokenSlice.reducer,
  auth: authSlice.reducer,
  article: articleSlice.reducer,
  recipe: recipeSlice.reducer,
  grocery: grocerySlice.reducer,
  meal: mealSlice.reducer,
  profile: profileSlice.reducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["token", "auth", "profile"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;
