/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import AddEmployeeModal from "./addEmployeeModal";
import { ColorButton } from "../../../components-general/buttons/coloredButton";
import CustomizedDataGrid from "../../newDashboard/components/CustomizedDataGrid";
import { FetchDataContext } from "../../../services/fetchContext/fetchContext";

const EmployeeHomePage = () => {
  const { fetchAllEmployees } = useContext(FetchDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [triggerFetch, setTriggerFetch] = useState(0);

  console.log(error);

  console.log(employeeData);
  useEffect(() => {
    fetchEmployee();
  }, [triggerFetch]);

  const handleOpen = () => setOpen(true);

  const fetchEmployee = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllEmployees();
      if (result.success) {
        setEmployeeData(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const columns2 = [
    { field: "name", headerName: "Name ", flex: 1, minWidth: 200 },

    {
      field: "jobTitle",
      headerName: " Job Title",

      flex: 1,
      minWidth: 80,
    },
    {
      field: "email",
      headerName: " Email",

      flex: 1,
      minWidth: 80,
    },
  ];

  return (
    <div className="p-5 w-full h-screen">
      <div className="mt-10 mb-4 flex flex-row-reverse">
        <ColorButton
          onClick={handleOpen}
          variant="outlined"
          startIcon={<FaUserPlus />}
        >
          Add Employee
        </ColorButton>
      </div>

      <AddEmployeeModal
        open={open}
        setTriggerFetch={setTriggerFetch}
        setOpen={setOpen}
      />
      <CustomizedDataGrid
        loading={isLoading}
        columns={columns2}
        rows={employeeData}
      />
    </div>
  );
};

export default EmployeeHomePage;
