import React, { createContext, useContext } from "react";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/profile/profileSlice";
import { ApiUpdateContext } from "../../context/apiCalls/apiUpdateContext";
export const FetchDataContext = createContext();

const FetchDataContextProvider = ({ children }) => {
  const { getAccessToken } = useContext(ApiUpdateContext);

  const API_BASE_URL = "https://api.dearxmom.com";
  const GET_ADMIN_PROFILE = `${API_BASE_URL}/admin/data`;
  const GET_ALL_ARTICLE = `${API_BASE_URL}/admin/education/all-articles`;
  const GET_ALL_MEALS = `${API_BASE_URL}/admin/nutrition/all-meals`;
  const GET_ALL_TIPS = `${API_BASE_URL}/admin/education/all-tips`;
  const GET_ALL_GROCERIES = `${API_BASE_URL}/admin/nutrition/all-groceries`;
  const GET_ALL_RECIPES = `${API_BASE_URL}/admin/nutrition/all-recipes`;
  const GET_ALL_EMPLOYEES = `${API_BASE_URL}/admin/all-employees`;
  const GET_ALL_ADMINS = `${API_BASE_URL}/admin/all-admins`;
  const GET_ALL_TASKS = `${API_BASE_URL}/admin/all-tasks`;
  const GET_ALL_BADGES = `${API_BASE_URL}/admin/users/all-badges`;
  const GET_META = `${API_BASE_URL}/app/counts`;
  const GET_ALL_CLINICIANS_VERIFY = `${API_BASE_URL}/admin/clinician/all-with-intentions`;
  const GET_A_CLINICIANS = `${API_BASE_URL}/admin/clinician/find`;

  const dispatch = useDispatch();

  const fetchAdminProfile = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ADMIN_PROFILE, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        dispatch(setProfile(data));
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const getCounts = async (label) => {
    try {
      const response = await fetch(GET_META, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        console.log(data);

        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching goals:", err.message);
      return { success: false, error: `Error fetching goals: ${err.message}` };
    }
  };
  const fetchAllArticles = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_ARTICLE, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllTips = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_TIPS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllMeals = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_MEALS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllGroceries = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_GROCERIES, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllRecipes = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_RECIPES, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllEmployees = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_EMPLOYEES, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };
  const fetchAllAdmins = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_ADMINS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };
  const fetchAllBadges = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_BADGES, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllTasks = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_TASKS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllClinicianVerify = async () => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(GET_ALL_CLINICIANS_VERIFY, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const findClinician = async (id) => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(`${GET_A_CLINICIANS}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  //get all data from articles

  return (
    <FetchDataContext.Provider
      value={{
        fetchAllArticles,
        fetchAllMeals,
        fetchAllGroceries,
        fetchAllRecipes,
        fetchAllEmployees,
        fetchAllAdmins,
        fetchAllTasks,
        fetchAllClinicianVerify,
        findClinician,
        fetchAllBadges,
        fetchAdminProfile,
        getCounts,
        fetchAllTips,
      }}
    >
      {children}
    </FetchDataContext.Provider>
  );
};

export default FetchDataContextProvider;
