import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  profile: null,
  profileImage: '',
  backgroundInfoSubmitted: false,
  professionVerified: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = {...state.profile, ...action.payload}; // Merge the new data with the existing data
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload; // Merge the new data with the existing data
    },
    updateBackgroundSubmitted: (state, action) => {
      state.isOnboard = action.payload;
    },
    updateProfessionVerified: (state, action) => {
      state.isOnboard = action.payload;
    },
    resetProfile: state => {
      state.profile = null;
    },
  },
});

export const {
  setProfile,
  resetProfile,
  setProfileImage,
  updateBackgroundSubmitted,
  updateProfessionVerified,
} = profileSlice.actions;
export default profileSlice;
