import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function HighlightedCard({
  title = "Explore your data",
  description = "Get insights on how to improve your business",
  buttonText = "View insights",
  icon = <InsightsRoundedIcon />,
  onClick,
  bg,
  buttonColor = "white",
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card sx={{ height: "100%", backgroundColor: bg }}>
      <CardContent>
        {icon}
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ mt: "8px", fontWeight: "600" }}
        >
          {title}
        </Typography>
        <Typography sx={{ color: "text.secondary", mb: "8px" }}>
          {description}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          color={buttonColor}
          endIcon={<ChevronRightRoundedIcon />}
          fullWidth={isSmallScreen}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );
}
