/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { ColorButton } from "../../../components-general/buttons/coloredButton";
import FindClinicianAndVerifyModal from "./findAndVerifyClinician";
import { Chip } from "@mui/material";
import CustomizedDataGrid from "../../newDashboard/components/CustomizedDataGrid";
import { FetchDataContext } from "../../../services/fetchContext/fetchContext";

function renderStatus(status) {
  const colors = {
    pending: "success",
    complete: "default",
  };

  return <Chip label={status} color={colors[status]} size="small" />;
}

const ManageClinicianHome = () => {
  const { fetchAllClinicianVerify } = useContext(FetchDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [clinicianData, setClinicianData] = useState([]);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [triggerFetch, setTriggerFetch] = useState(0);

  console.log(error);

  useEffect(() => {
    fetchEmployee();
  }, [triggerFetch]);

  const handleOpen = () => setOpen(true);

  const fetchEmployee = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllClinicianVerify();
      if (result.success) {
        setClinicianData(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const columns2 = [
    { field: "id", headerName: "User ID", flex: 1.5, minWidth: 200 },
    {
      field: "verificationStatus",
      headerName: "Status",
      flex: 0.5,
      minWidth: 80,
      renderCell: (params) => renderStatus(params.value),
    },
    {
      field: "first_name",
      headerName: "First Name",

      flex: 0.5,
      minWidth: 80,
    },
    {
      field: "last_name",
      headerName: "Last Name",

      flex: 0.5,
      minWidth: 80,
    },
    {
      field: "email",
      headerName: "Email",

      flex: 1,
      minWidth: 100,
    },
  ];

  return (
    <div className="p-2 w-full h-screen">
      <div className=" mb-4 flex flex-row-reverse">
        <ColorButton
          onClick={handleOpen}
          variant="outlined"
          startIcon={<VerifiedIcon />}
        >
          Verify Clinician
        </ColorButton>
      </div>
      <FindClinicianAndVerifyModal
        open={open}
        setTriggerFetch={setTriggerFetch}
        setOpen={setOpen}
      />
      <CustomizedDataGrid
        loading={isLoading}
        columns={columns2}
        rows={clinicianData}
      />
    </div>
  );
};

export default ManageClinicianHome;
