import * as React from "react";
import Box from "@mui/material/Box";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import VerifiedIcon from "@mui/icons-material/Verified";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { Chip } from "@mui/material";

import { ColorButton } from "../../../../../components-general/buttons/coloredButton";
import AddTaskForUsers from "./addTaskForUsers";
import { ApiPostContext } from "../../../../../context/apiCalls/apiPostContext";
import { FetchDataContext } from "../../../../../services/fetchContext/fetchContext";

function renderStatus(value) {
  const status = value; // Assuming this is a boolean
  const isVerified = status === true; // Check if the status is true (verified)

  // Determine the label text and styles based on verification status
  const statusText = isVerified ? "approved" : "not approved";

  return (
    <Chip
      label={statusText}
      color={statusText === "approved" ? "success" : "default"}
      size="small"
    />
  );
}

export default function ManageUserTasks() {
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [tasks, setTasks] = React.useState([]);
  const { fetchAllTasks } = React.useContext(FetchDataContext);
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [triggerFetch, setTriggerFetch] = React.useState(0);

  const navigate = useNavigate();
  const { approveTask } = React.useContext(ApiPostContext);
  const notify = () => {
    toast.success("Successfully Saved !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };
  const notifyError = () => {
    toast.error("Error Saving !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const handleApprove = async (id) => {
    setIsLoading(true);
    try {
      const result = await approveTask(id);
      if (result.success) {
        console.log("Successful");
        setTriggerFetch((prev) => prev + 1);

        notify();
      } else {
        notifyError();

        if (result.error) {
          setError("Could not approve");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  console.log(error);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  React.useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFetch]);

  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllTasks();
      if (result.success) {
        setTasks(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Could not retrieve articles");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    const selectedRow = tasks.find((row) => row.id === id);
    navigate(`/tasks/update-task`, { state: selectedRow });
    console.log("Selected Row:", selectedRow);
  };

  const handleApproveClick = (id) => () => {
    const selectedRow = tasks.find((row) => row.id === id);
    console.log("Selected Row:", selectedRow);
    handleApprove(id);
  };

  const handleDeleteClick = (id) => () => {
    setTasks(tasks.filter((row) => tasks.id !== id));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setTasks(tasks.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns2 = [
    { field: "id", headerName: "Task ID", flex: 1, minWidth: 200 },
    {
      field: "name",
      headerName: "Name of Task",
      flex: 0.5,
      minWidth: 200,
      editable: true,
    },
    {
      field: "approved",
      headerName: "Approval",
      flex: 0.5,
      minWidth: 80,
      renderCell: (params) => renderStatus(params.value),
    },
    {
      field: "subtitle",
      headerName: " Description",
      flex: 1,
      minWidth: 80,
      editable: true,
    },
    {
      field: "category",
      headerName: " Category",
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Preconception",
        "Pregnancy",
        "Postpartum",
        "General",
        "Clinician",
      ],
      flex: 0.5,
      minWidth: 80,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<VerifiedIcon />}
            label="verify"
            className="textPrimary"
            onClick={handleApproveClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <div className="m-2 ">
        <p className="font-medium text-red-700 text-sm">{error}</p>
      </div>
      <ToastContainer />

      <div className=" mb-4 flex flex-row-reverse">
        <ColorButton
          onClick={() => {
            handleOpenModal();
          }}
          variant="outlined"
          startIcon={<FaUserPlus />}
        >
          Add Task
        </ColorButton>
      </div>

      <DataGrid
        rows={tasks}
        columns={columns2}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        loading={isLoading}
      />
      <AddTaskForUsers
        open={openModal}
        setTriggerFetch={setTriggerFetch}
        setOpen={setOpenModal}
      />
    </Box>
  );
}
