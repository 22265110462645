import React, { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { updateToken } from "../../redux/token/tokenSlice";

export const ApiUpdateContext = createContext();

//const BASEURL = "https://dearxmom-admin-backend.azurewebsites.net";

const ApiUpdateContextProvider = ({ children }) => {
  const { token } = useSelector((state) => state.token);
  console.log(token);

  const API_BASE_URL = "https://api.dearxmom.com";
  const UPDATE_ARTICLE = `${API_BASE_URL}/admin/education/update-article`;
  const UPDATE_MEALS = `${API_BASE_URL}/admin/nutrition/update-meal`;
  const UPDATE_GROCERIES = `${API_BASE_URL}/admin/nutrition/update-grocery`;
  const UPDATE_RECIPES = `${API_BASE_URL}/admin/nutrition/update-recipe`;
  const UPDATE_TASK = `${API_BASE_URL}/admin/update-task`;

  const RENEW_TOKEN = `${API_BASE_URL}/auth/admin/renew-token`;
  const dispatch = useDispatch();

  async function refreshAccessToken(refreshToken) {
    try {
      const response = await fetch(RENEW_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });
      if (response.ok) {
        const res = await response.json();
        dispatch(updateToken(res));
        console.log("new token");
        console.log(res);
        return { success: true, data: res };
      } else {
        const res = await response.json();
        console.log(res);
        return { success: false, error: res.message };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  }

  async function getAccessToken() {
    const accessToken = token.access_token;
    const refreshToken = token.refresh_token;

    // Decode the token to get its expiration time
    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000; // Current time in seconds

    // Check if the access token will expire soon or has already expired
    if (decodedToken.access_token_expiration_time < currentTime + 60) {
      // 60 seconds before expiration
      // Refresh the access token
      return await refreshAccessToken(refreshToken);
    }

    return accessToken;
  }
  const updateRecipes = async (data) => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(UPDATE_RECIPES, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const updateGroceries = async (data) => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(UPDATE_GROCERIES, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const updateMeals = async (data) => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(UPDATE_MEALS, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const updateArticle = async (data) => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(UPDATE_ARTICLE, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const updateTask = async (data) => {
    const accessToken = await getAccessToken();
    const d = { task: data };
    console.log(d);
    try {
      const response = await fetch(UPDATE_TASK, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          task: data,
        }),
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };
  return (
    <ApiUpdateContext.Provider
      value={{
        updateRecipes,
        updateGroceries,
        updateMeals,
        updateArticle,
        getAccessToken,
        updateTask,
      }}
    >
      {children}
    </ApiUpdateContext.Provider>
  );
};

export default ApiUpdateContextProvider;
