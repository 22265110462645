import { LoadingButton } from "@mui/lab";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { ColorLoadingButton } from "../../../../users/screens/addEmployeeModal";
import UpdateIcon from "@mui/icons-material/Update";
import VerifiedIcon from "@mui/icons-material/Verified";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import CustomTextInput from "../../../../../components-general/textInput/customTextInput";
import CustomSelect from "../../../../../components-general/selector/customSelect";
import CustomTextArea from "../../../../../components-general/textArea/customTextArea";
import { ApiPostContext } from "../../../../../context/apiCalls/apiPostContext";
import { ApiUpdateContext } from "../../../../../context/apiCalls/apiUpdateContext";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Grid2";
import NavbarBreadcrumbs from "../../../../newDashboard/components/NavbarBreadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
export const statuses = [
  { title: "Completed", value: "completed" },
  { title: "In Progress", value: "in progress" },
  { title: "Update", value: "update" },
  { title: "Starting", value: "to start" },
  { title: "Testing", value: "test" },
];

export const categories = [
  { title: "Preconception", value: "Preconception" },
  { title: "Pregnancy", value: "Pregnancy" },
  { title: "Postpartum", value: "Postpartum" },
  { title: "General", value: "General" },
  { title: "Clinician", value: "Clinician" },
  { title: "Testing", value: "test" },
];

export const taskTypes = [
  { title: "Data Management", value: "dataManagement" },
  { title: "Assessment", value: "assessment" },
  { title: "Getting Started", value: "getStarted" },
  { title: "Testing", value: "test" },
];
export default function UpdateUserTask() {
  const [isLoading, setIsLoading] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [nav, setNavigation] = useState("");

  const [typeOfTask, setTypeOfTask] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [update, setUpdate] = useState(false);

  console.log("typeOfTask" + typeOfTask);
  const [id, setID] = useState(false);
  const [error, setError] = React.useState("");

  const { postTask, approveTask } = useContext(ApiPostContext);
  const { updateTask } = useContext(ApiUpdateContext);

  console.log(error);
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      const data = location.state;
      setName(data?.name);
      setCategory(data?.category);
      setStatus(data?.status);
      setTypeOfTask(data?.typeOfTask);
      setSubtitle(data?.subtitle);
      setID(data?.id);
      setUpdate(true);
      setNavigation(data?.navName);
      console.log("Data from here");
      console.log(data);
    }
  }, [location]);

  const notify = () => {
    toast.success("Successfully Saved !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const notifyError = () => {
    toast.error("Error Saving !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };
  const handleClick = async (event) => {
    event.preventDefault();
    const details = {
      name: name,
      status: status,
      typeOfTask: typeOfTask,
      subtitle: subtitle,
      category: category,
    };

    setIsLoading(true);

    try {
      const result = await postTask(details);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let details = {};
    if (update) {
      details = {
        name: name,
        status: status,
        typeOfTask: typeOfTask,
        subtitle: subtitle,
        category: category,
        navName: nav,
        id: id,
      };
    } else {
      details = {
        name: name,
        status: status,
        typeOfTask: typeOfTask,
        subtitle: subtitle,
        category: category,
        navName: nav,
      };
    }

    setIsLoading(true);

    try {
      const result = await updateTask(details);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    setIsApproving(true);
    try {
      const result = await approveTask(id);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        if (result.error) {
          setError("Could not approve");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsApproving(false);
    }
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/articles">
      Tasks
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Update Task
    </Typography>,
  ];
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <NavbarBreadcrumbs breadcrumbs={breadcrumbs} />

      <form>
        <div className="my-5  ">
          <p className="font-bold text-xl text-mainColor ">Update Task</p>
        </div>
        <ToastContainer />

        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <CustomSelect
              items={categories}
              onChange={(e) => setCategory(e.target.value)}
              value={category}
              label="Task Category"
              defaultText="Select Category "
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <CustomSelect
              items={taskTypes}
              onChange={(e) => setTypeOfTask(e.target.value)}
              value={typeOfTask}
              label="Type of Task "
              defaultText="Select Task Type "
            />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <CustomSelect
              items={statuses}
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              label="Default Status "
              defaultText="Select Status "
            />
          </Grid2>
        </Grid2>
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <CustomTextInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name here ..."
              label="Name "
            />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <CustomTextInput
              value={nav}
              onChange={(e) => setNavigation(e.target.value)}
              placeholder="Enter navigation name here ..."
              label="Navigation Name "
            />
          </Grid2>
        </Grid2>
        <Grid2
          container
          spacing={2}
          columns={6}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <CustomTextArea
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              placeholder="Describe the recipe here"
              label="Task Description"
            />
          </Grid2>
        </Grid2>

        <Stack className="mt-5  " direction="row" spacing={2}>
          {update ? (
            <ColorLoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<UpdateIcon />}
              variant="outlined"
              onClick={handleUpdate}
            >
              Update
            </ColorLoadingButton>
          ) : (
            <ColorLoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              onClick={handleClick}
            >
              Save
            </ColorLoadingButton>
          )}
          <LoadingButton
            loading={isApproving}
            loadingPosition="start"
            startIcon={<VerifiedIcon />}
            variant="outlined"
            onClick={handleApprove}
          >
            Approve
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
}
