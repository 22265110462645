import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import HighlightedCard from "../../newDashboard/components/HighlightedCard";
import Grid2 from "@mui/material/Grid2";
import AddTaskIcon from "@mui/icons-material/AddTask";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
export default function ManageUsers() {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Grid2
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <Grid2 size={{ xs: 6, sm: 6, lg: 3 }}>
          <HighlightedCard
            title="Create Tasks"
            description="Create tasks that users can work on in the dearx mom app"
            buttonText="Create now"
            icon={<AddTaskIcon />}
            onClick={() => navigate("/tasks")}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 6, lg: 3 }}>
          <HighlightedCard
            title="Create Badge"
            description="Create badges that users can earn in the dearx mom app"
            buttonText="Create now"
            icon={<MilitaryTechIcon />}
            onClick={() => navigate("/badge")}
            bg="white"
          />
        </Grid2>
      </Grid2>
    </Box>
  );
}
