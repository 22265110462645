/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { MenuItem, Menu, Chip } from "@mui/material";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
//import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { IoAdd } from "react-icons/io5";

import VerifiedIcon from "@mui/icons-material/Verified";
import { ColorButton } from "../../components-general/buttons/coloredButton";
import { FetchDataContext } from "../../services/fetchContext/fetchContext";
import DailyTipModal from "./dailyTipsModal";
import { useSelector } from "react-redux";
import { ApiPostContext } from "../../context/apiCalls/apiPostContext";

function renderStatus(value) {
  const status = value; // Assuming this is a boolean
  const isVerified = status === true; // Check if the status is true (verified)

  // Determine the label text and styles based on verification status
  const statusText = isVerified ? "approved" : "not approved";

  return (
    <Chip
      label={statusText}
      color={statusText === "approved" ? "success" : "default"}
      size="small"
    />
  );
}

const DailyTipsHomePage = () => {
  const [tips, setTips] = useState([]);
  const { fetchAllTips } = useContext(FetchDataContext);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [triggerFetch, setTriggerFetch] = useState(0);
  const [author, setAuthor] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [currentTip, setCurrentTip] = useState({});

  const { profile } = useSelector((state) => state.profile);
  const { approveTip } = useContext(ApiPostContext);

  React.useEffect(() => {
    if (profile) {
      setAuthor(profile.name);
    }
  }, [profile]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => setOpenModal(true);

  useEffect(() => {
    fetchTips();
  }, [triggerFetch]);

  const fetchTips = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllTips();
      if (result.success) {
        setTips(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Could not retrieve tips");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const approveTheTip = async (id) => {
    try {
      const result = await approveTip(id);
      if (result.success) {
        console.log("Successful");
        setTriggerFetch((prev) => prev + 1);
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
    }
  };

  const handleView = () => {
    handleClose(); // Close the menu
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    const selectedRow = tips?.find((row) => row?.id === id);
    console.log(selectedRow);
    setCurrentTip(selectedRow);
    setOpenModal(true);
  };

  const handleApproveClick = (id) => () => {
    const selectedRow = tips?.find((row) => row.id === id);
    console.log("Selected Row:", selectedRow);
    approveTheTip(id);
  };

  /*const handleDeleteClick = (id) => () => {
    setTips(tips?.filter((row) => row.id !== id));
  };*/

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setTips(tips?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns2 = [
    { field: "author", headerName: " Author", flex: 0.5, minWidth: 200 },

    { field: "tip", headerName: "Tip", flex: 1, minWidth: 200 },
    {
      field: "category",
      headerName: " Categories",

      flex: 1,
      minWidth: 80,
    },
    {
      field: "approved",
      headerName: "Approval",
      flex: 0.5,
      minWidth: 80,
      renderCell: (params) => renderStatus(params.value),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<VerifiedIcon />}
            label="verify"
            className="textPrimary"
            onClick={handleApproveClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          /* <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,*/
        ];
      },
    },
  ];

  return (
    <div className="p-5 w-full h-screen">
      <div className=" mb-4 flex flex-row-reverse">
        <ColorButton
          onClick={handleOpenModal}
          variant="outlined"
          startIcon={<IoAdd />}
        >
          Add Tip
        </ColorButton>
      </div>

      <div className="m-2 ">
        <p className="font-medium text-red-700 text-sm">{error}</p>
      </div>
      <DailyTipModal
        open={openModal}
        setTriggerFetch={setTriggerFetch}
        setOpen={setOpenModal}
        author={author}
        selectedTip={currentTip}
      />
      <DataGrid
        rows={tips}
        columns={columns2}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        loading={isLoading}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleView}>View</MenuItem>
      </Menu>
    </div>
  );
};

export default DailyTipsHomePage;
