import { createBrowserRouter } from "react-router-dom";

import ArticlesHomePage from "../features/article/screens/articlesHome";
import AddNewArticles from "../features/article/screens/addNewArticle";

import { ProtectedRoute } from "./protectedRoutes";

import EmployeeHomePage from "../features/users/screens/employee";
import ManageClinicianHome from "../features/clinicians/manageClinician/manageClinicians";
import AdminsHomePage from "../features/users/screens/adminsHome";
import UpdateUserTask from "../features/dearxapps/usersMom/manageTasks/screens/updateTask";
import NewDashboard from "../features/newDashboard/NewDashboard";
import ManageUsers from "../features/users/screens/manageUsers";
import ManageUserBadges from "../features/dearxapps/usersMom/badges/manageBadges";
import SignInSide from "../features/login/SignInSide";
import ManageUserTasks from "../features/dearxapps/usersMom/manageTasks/screens/manageTasks";
import ShopHomeScreen from "../features/shop/homeScreen";
import DailyTipsHomePage from "../features/dailyTip/dailyTipsHome";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <SignInSide />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <NewDashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "articles",
        element: <ArticlesHomePage />,
      },

      {
        path: "articles/new-article",
        element: <AddNewArticles />,
      },
      {
        path: "shop",
        element: <ShopHomeScreen />,
      },
      {
        path: "admins",
        element: <AdminsHomePage />,
      },

      {
        path: "employees",
        element: <EmployeeHomePage />,
      },
      {
        path: "clinicians",
        element: <ManageClinicianHome />,
      },
      {
        path: "users",
        element: <ManageUsers />,
      },
      {
        path: "tasks",
        element: <ManageUserTasks />,
      },
      {
        path: "tips",
        element: <DailyTipsHomePage />,
      },
      {
        path: "badge",
        element: <ManageUserBadges />,
      },
      {
        path: "tasks/update-task",
        element: <UpdateUserTask />,
      },
    ],
  },
]);

export default router;
