import * as React from "react";
import Box from "@mui/material/Box";
import ReactLoading from "react-loading";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Close";
import { Bounce, toast, ToastContainer } from "react-toastify";

import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";

import { Chip } from "@mui/material";
import { ColorButton } from "../../../../components-general/buttons/coloredButton";
import { FaUserPlus } from "react-icons/fa";
import CreateBadgeModal from "./addBadge";
import { ApiPostContext } from "../../../../context/apiCalls/apiPostContext";
import { FetchDataContext } from "../../../../services/fetchContext/fetchContext";

function renderStatus(value) {
  const status = value; // Assuming this is a boolean
  const isVerified = status === true; // Check if the status is true (verified)

  // Determine the label text and styles based on verification status
  const statusText = isVerified ? "approved" : "not approved";

  return (
    <Chip
      label={statusText}
      color={statusText === "approved" ? "success" : "default"}
      size="small"
    />
  );
}

export default function ManageUserBadges() {
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [badgeData, setBadgeData] = React.useState([]);
  const [isApproving, setIsApproving] = React.useState(false);

  const { fetchAllBadges } = React.useContext(FetchDataContext);
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [triggerFetch, setTriggerFetch] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const { approveBadge } = React.useContext(ApiPostContext);

  const handleOpen = () => setOpen(true);

  React.useEffect(() => {
    fetchBadge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFetch]);

  const fetchBadge = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllBadges();
      if (result.success) {
        setBadgeData(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };
  const notify = () => {
    toast.success("Successfully Saved !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const handleApprove = async (id) => {
    setIsApproving(true);
    try {
      const result = await approveBadge(id);
      if (result.success) {
        console.log("Successful");
        setTriggerFetch((prev) => prev + 1);

        notify();
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsApproving(false);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    const selectedRow = badgeData.find((row) => row.id === id);
    console.log("Selected Row:", selectedRow);
    handleApprove(id);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setBadgeData(badgeData.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = badgeData.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setBadgeData(badgeData.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setBadgeData(
      badgeData.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns2 = [
    {
      field: "title",
      headerName: "Title",
      flex: 0.5,
      minWidth: 200,
      editable: true,
    },
    {
      field: "approved",
      headerName: "Approval",
      flex: 0.5,
      minWidth: 80,
      renderCell: (params) => renderStatus(params.value),
    },
    {
      field: "description",
      headerName: " Description",
      flex: 1,
      minWidth: 80,
      editable: true,
    },
    {
      field: "criteria",
      headerName: " Criteria",
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Preconception",
        "Pregnancy",
        "Postpartum",
        "General",
        "Clinician",
      ],
      flex: 0.5,
      minWidth: 80,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<VerifiedIcon />}
            label="verify"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (isApproving) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ReactLoading type="spin" color="#00a99d" height={"5%"} width={"5%"} />
      </div>
    );
  }

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <div className="m-2 ">
        <p className="font-medium text-red-700 text-sm">{error}</p>
      </div>
      <div className=" mb-4 flex flex-row-reverse">
        <ColorButton
          onClick={handleOpen}
          variant="outlined"
          startIcon={<FaUserPlus />}
        >
          Add Badge
        </ColorButton>
      </div>
      <ToastContainer />

      <DataGrid
        rows={badgeData}
        columns={columns2}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        loading={isLoading}
      />
      <CreateBadgeModal
        open={open}
        setTriggerFetch={setTriggerFetch}
        setOpen={setOpen}
      />
    </Box>
  );
}
