import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import CustomSelect from "../../../components-general/selector/customSelect";
import CustomTextInput from "../../../components-general/textInput/customTextInput";
import { AuthContext } from "../../../context/authentication/authContext";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordInput from "../../../components-general/textInput/passwordTextInput";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { ColorLoadingButton } from "./addEmployeeModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CreateAdminModal({ open, setOpen, setTriggerFetch }) {
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);

  const [role, setRole] = useState("");
  console.log(role);
  const adminRoles = [
    {
      title: "Super Admin",
      value: "super_admin",
    },
    {
      title: "Member",
      value: "member",
    },
    {
      title: "Writer",
      value: "writer",
    },
  ];
  const { createAdmin } = useContext(AuthContext);

  console.log(error);

  const notify = () => {
    toast.success("Successfully Added !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await createAdmin(email, role, password);
      if (result.success) {
        console.log("Successful");
        notify();
        setEmail("");
        setPassword("");
        setTriggerFetch((prev) => prev + 1);
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="mt-5 mb-5  ">
            <p className="font-bold text-lg text-mainColor ">Add Admin</p>
          </div>
          <form onSubmit={handleAddEmployee}>
            <div className=" mb-5">
              <CustomTextInput
                value={email}
                onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
                placeholder="Email of admin ..."
                label="Email "
                type="email"
              />
            </div>
            <div className=" mb-5">
              <PasswordInput
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value.replace(/\s/g, ""))}
                placeholder="Password of user ..."
                label="Password "
              />
            </div>
            <div className="mb-5">
              <CustomSelect
                items={adminRoles}
                onChange={(e) => setRole(e.target.value)}
                value={role}
                label="Role"
                defaultText="Select Role  "
              />
            </div>
            <Stack direction="row" spacing={2}>
              <ColorLoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                type="submit"
              >
                Save
              </ColorLoadingButton>
              <Button onClick={handleClose} variant="outlined" size="medium">
                Cancel
              </Button>
            </Stack>
          </form>
          <ToastContainer />
        </Box>
      </Modal>
    </div>
  );
}
