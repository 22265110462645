import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CustomTextInput from "../../../../components-general/textInput/customTextInput";
import CustomSelect from "../../../../components-general/selector/customSelect";
import { ColorLoadingButton } from "../../../users/screens/addEmployeeModal";
import CustomTextArea from "../../../../components-general/textArea/customTextArea";
import { ApiPostContext } from "../../../../context/apiCalls/apiPostContext";

const style = {
  position: "absolute",
  overflow: "scroll",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CreateBadgeModal({ open, setOpen, setTriggerFetch }) {
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState("");
  const [criteria, setCriteria] = useState("");

  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);

  const allCriteria = [
    {
      title: "Onboarding",
      value: "Onboarding",
    },
    {
      title: "Clinician Verified",
      value: "Clinician_verified",
    },
    {
      title: "Testing ",
      value: "Testing",
    },
  ];
  const { postBadge } = useContext(ApiPostContext);

  console.log(error);
  const handleDescription = (e) => {
    setDescription(e.target.value);
    //dispatch(setRecipeDescription(e.target.value));
  };

  const notify = () => {
    toast.success("Successfully Added !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    //dispatch(setArticleImage(e.target.files[0]));
    //dispatch(setPreviewImage(URL.createObjectURL(e.target.files[0])));
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    const details = {
      title: title,
      description: description,
      criteria: criteria,
    };
    const data = new FormData();

    data.append("details", JSON.stringify(details));

    data.append("image", image);
    setIsLoading(true);
    try {
      const result = await postBadge(data);
      if (result.success) {
        console.log("Successful");
        notify();
        setTriggerFetch((prev) => prev + 1);
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className=" mb-5  ">
            <p className="font-bold text-lg text-mainColor ">Add Admin</p>
          </div>
          <form onSubmit={handleAddEmployee}>
            <div className="flex flex-row items-center ">
              <div className=" mb-5">
                <div>
                  <div class="w-full">
                    <label
                      for="dropzone-file"
                      class="flex flex-col items-center justify-center min-w-20 h-28 border border-gray-300  rounded-lg cursor-pointer bg-white"
                    >
                      <div class="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          class="w-8 h-8 mb-1 mt-3 text-gray-500 "
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p class="m-4 text-sm text-gray-500 dark:text-gray-400">
                          <span class="font-semibold">Click to upload</span>
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        class="hidden"
                        onChange={handleImage}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {previewImage && (
                <div className="mb-5 mt-3">
                  <img
                    src={previewImage}
                    width={100}
                    height={100}
                    alt="badge "
                  />
                </div>
              )}
            </div>
            <div className=" mb-5">
              <CustomTextInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title of badge ..."
                //label="Title"
              />
            </div>

            <div className="mb-5">
              <CustomTextArea
                value={description}
                onChange={handleDescription}
                placeholder="Describe the badge here"
                //label="Description"
              />
            </div>
            <div className="mb-5">
              <CustomSelect
                items={allCriteria}
                onChange={(e) => setCriteria(e.target.value)}
                value={criteria}
                // label="Criteria"
                defaultText="Select Criteria "
              />
            </div>
            <Stack direction="row" spacing={2}>
              <ColorLoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                type="submit"
              >
                Save
              </ColorLoadingButton>
              <Button onClick={handleClose} variant="outlined" size="medium">
                Cancel
              </Button>
            </Stack>
          </form>
          <ToastContainer />
        </Box>
      </Modal>
    </div>
  );
}
