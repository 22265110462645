import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Bounce, toast, ToastContainer } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import VerifiedIcon from "@mui/icons-material/Verified";
import Stack from "@mui/material/Stack";
import { ColorLoadingButton } from "../users/screens/addEmployeeModal";
import CustomTextArea from "../../components-general/textArea/customTextArea";
import { ApiPostContext } from "../../context/apiCalls/apiPostContext";
import MultipleSelectChip from "../../components-general/selector/mulitselector";

const categories = [
  "Preconception",
  "Pregnancy",
  "Postpartum",
  "Clinician",
  "Partner",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DailyTipModal({
  setTriggerFetch,
  open,
  setOpen,
  author,
  selectedTip,
}) {
  const [id, setID] = React.useState("");
  const [tip, setTip] = React.useState("");
  const [tipCategory, setTipCategory] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [verify, setVerify] = useState(false);
  const [verifying, setVerifying] = useState(false);
  console.log("selectedTip");
  console.log(selectedTip);

  useEffect(() => {
    if (selectedTip) {
      setTip(selectedTip.tip);
      setTipCategory(selectedTip.category);
    }
  }, [selectedTip]);

  console.log(tipCategory);
  const [error, setError] = React.useState("");
  console.log(error);
  const { approveTip, postTips } = useContext(ApiPostContext);

  const handleTip = (e) => {
    setTip(e.target.value);
    //dispatch(setArticleSummary(e.target.value));
  };

  const handleClose = () => setOpen(false);

  const notify = () => {
    toast.success("Clinician Found !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const notifyError = () => {
    toast.error("Error Saving !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };
  const approveTheTip = async (e) => {
    e.preventDefault();
    setVerifying(true);
    try {
      const result = await approveTip(id);
      if (result.success) {
        console.log("Successful");
        setTriggerFetch((prev) => prev + 1);
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setVerifying(false);
    }
  };
  const handleClick = async (event) => {
    event.preventDefault();
    const details = {
      tip: tip,
      category: tipCategory,
      author: author,
    };
    const data = new FormData();

    data.append("details", JSON.stringify(details));

    setIsLoading(true);

    try {
      const result = await postTips(data);
      if (result.success) {
        console.log("Successful");

        setID(result.data.id);
        setVerify(true);
        setTriggerFetch((prev) => prev + 1);
        console.log(result.data.id);
        notify();
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className=" mb-5  ">
            <p className="font-bold text-lg text-mainColor ">Add Daily Tip</p>
          </div>
          <div className=" mb-8">
            <div className=" mb-4">
              <CustomTextArea
                value={tip}
                onChange={handleTip}
                placeholder="Enter tip here ..."
                //  label=" Description"
              />
            </div>
            <div className=" mb-4">
              <MultipleSelectChip
                personName={tipCategory}
                setPersonName={setTipCategory}
                label="Select Category"
                names={categories}
              />
            </div>
          </div>
          <Stack direction="row" spacing={2}>
            {verify ? (
              <LoadingButton
                loading={verifying}
                loadingPosition="start"
                startIcon={<VerifiedIcon />}
                variant="outlined"
                onClick={approveTheTip}
              >
                Approve
              </LoadingButton>
            ) : (
              <ColorLoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                onClick={handleClick}
              >
                Save
              </ColorLoadingButton>
            )}

            <Button onClick={handleClose} variant="outlined" size="medium">
              Cancel
            </Button>
          </Stack>
          <ToastContainer />
        </Box>
      </Modal>
    </div>
  );
}
