import React from "react";

export default function CustomTextArea({
  label,
  value,
  onChange,
  placeholder,
}) {
  return (
    <div>
      <p className="font-semibold text-sm mb-3">{label}</p>
      <textarea
        value={value}
        placeholder={placeholder}
        className="w-full  min-h-40 outline-none border rounded-lg shadow-sm  py-2 px-3 text-sm"
        onChange={onChange}
      ></textarea>
    </div>
  );
}
