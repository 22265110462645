/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { MenuItem, Menu, Chip } from "@mui/material";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { IoAdd } from "react-icons/io5";

import VerifiedIcon from "@mui/icons-material/Verified";
import { ColorButton } from "../../../components-general/buttons/coloredButton";
import { FetchDataContext } from "../../../services/fetchContext/fetchContext";

function renderStatus(value) {
  const status = value; // Assuming this is a boolean
  const isVerified = status === true; // Check if the status is true (verified)

  // Determine the label text and styles based on verification status
  const statusText = isVerified ? "approved" : "not approved";

  return (
    <Chip
      label={statusText}
      color={statusText === "approved" ? "success" : "default"}
      size="small"
    />
  );
}

const ArticlesHomePage = () => {
  const [articles, setArticles] = useState([]);
  const { fetchAllArticles } = useContext(FetchDataContext);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllArticles();
      if (result.success) {
        setArticles(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Could not retrieve articles");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleView = () => {
    handleClose(); // Close the menu
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    const selectedRow = articles.find((row) => row.id === id);
    navigate(`/articles/new-article`, { state: selectedRow });
    console.log("Selected Row:", selectedRow);
  };

  const handleApproveClick = (id) => () => {
    const selectedRow = articles.find((row) => row.id === id);
    console.log("Selected Row:", selectedRow);
    // handleApprove(id);
  };

  const handleDeleteClick = (id) => () => {
    setArticles(articles.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setArticles(
      articles.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    console.log(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns2 = [
    { field: "author", headerName: " Author", flex: 0.5, minWidth: 200 },

    { field: "title", headerName: "Title", flex: 1, minWidth: 200 },

    {
      field: "approved",
      headerName: "Approval",
      flex: 0.5,
      minWidth: 80,
      renderCell: (params) => renderStatus(params.value),
    },

    {
      field: "category",
      headerName: " Category",

      flex: 0.5,
      minWidth: 80,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<VerifiedIcon />}
            label="verify"
            className="textPrimary"
            onClick={handleApproveClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="p-5 w-full h-screen">
      <div className=" mb-4 flex flex-row-reverse">
        <ColorButton
          onClick={() => {
            navigate(`/articles/new-article`);
          }}
          variant="outlined"
          startIcon={<IoAdd />}
        >
          Add Article
        </ColorButton>
      </div>

      <div className="m-2 ">
        <p className="font-medium text-red-700 text-sm">{error}</p>
      </div>
      <DataGrid
        rows={articles}
        columns={columns2}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        loading={isLoading}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleView}>View</MenuItem>
      </Menu>
    </div>
  );
};

export default ArticlesHomePage;
